import React from 'react';
import './App.css';
import { Customers } from './Customers'
import { Header } from './Header'
import { ExportReactCSV } from './ExportReactCSV'
import Web3 from 'web3';

const web3providers = new Web3.providers.HttpProvider('https://bsc-dataseed2.ninicoin.io');
const web3 = new Web3(web3providers);

const checkAddr = "0x9394F179ec70786AdbcE2F092ADaa9a6B929CA56";
const checkAbi = require('./checkAbi.json');
const checkContract = new web3.eth.Contract(checkAbi, checkAddr);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { customers: [] ,fileName: 'Mint用户数据统计表'};
  }
  
  async componentDidMount(){
    const data =await checkContract.methods.getMintRanks().call();
    let custs = []
    for(let i=0;i<data[0].length;i++){
      const _total = Web3.utils.fromWei(data[3][i],'ether')
      if(_total*1>0){
        const dics ={
          address:data[0][i],
          svalue:Web3.utils.fromWei(data[1][i],'ether'),
          ivalue:Web3.utils.fromWei(data[2][i],'ether'),
          total:Web3.utils.fromWei(data[3][i],'ether'),
        }
        custs.push(dics)
      }
    }
    console.log('custs',custs)

    this.setState({ customers: custs });
  }
  
  // customers = () => {
  //   let custs = []
  //   for (let i = 0; i <= 5; i++) {
  //     custs.push({address: `first${i}`, svalue: `last${i}`,
  //       ivalue: `abc${i}@gmail.com`, total: `000${i} street city, ST`});
  //   }
  //   return custs;
  // }

  // state = {
  //   customers: this.customers(),
  //   fileName: 'Customers'
  // }
  
  render() {

    return (
      <div className="App">
        <Header />
        <div className="row">
            {/* <div className="col-md-8">
                <h2>条目总数</h2>
            </div> */}
            <div className="col-md-4 center">
                <ExportReactCSV csvData={this.state.customers} fileName={this.state.fileName} />
            </div>
        </div>
        <Customers customers={this.state.customers}/>
      </div>
    );
  }
}

export default App;
